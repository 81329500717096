<template>
  <section class="text-gray-600 body-font">
    <div class="container px-5 py-24 mx-auto">
      <div class="flex flex-col text-center w-full mb-20">
        <h1
          class="
            text-2xl
            font-medium
            title-font
            mb-4
            text-gray-900
            tracking-widest
          "
        >
          OUR APPS
        </h1>
        <p class="lg:w-2/3 mx-auto leading-relaxed text-base">
          Our Professional Apps are predefined services provided by us, it can
          include our open source professional Apps.
          <br />
          We’re working on more exciting apps that will release on the holiday
          of 2021.
        </p>
      </div>
      <div class="flex flex-wrap -m-4">
        <div class="p-4 lg:w-1/2">
          <a href="https://www.covid19.earth/" target="_blank">
            <div
              class="
                h-full
                flex
                sm:flex-row
                flex-col
                items-center
                sm:justify-start
                justify-center
                text-center
                sm:text-left
                transform
                transition
                duration-500
                hover:scale-110
                border-2
              "
            >
              <img
                alt="team"
                class="
                  flex-shrink-0
                  rounded-lg
                  w-48
                  h-48
                  object-cover object-center
                  sm:mb-0
                  mb-4
                  
                "
                src="https://avatars.githubusercontent.com/u/84667932?s=200&v=4"
              />
              <div class="flex-grow sm:pl-8">
                <h2 class="title-font font-medium text-lg text-gray-900">
                  COVID19 Dashboard
                </h2>
                <p class="mb-4">
                  Open Source Super Fast COVID-19 Dashboard and parser for
                  multiple countries.
                </p>
              </div>
            </div>
          </a>
        </div>
        <div class="p-4 lg:w-1/2">
          <a href="https://zestframework.github.io/" target="_blank">
            <div
              class="
                h-full
                flex
                sm:flex-row
                flex-col
                items-center
                sm:justify-start
                justify-center
                text-center
                sm:text-left
                transform
                transition
                duration-500
                hover:scale-110
                border-2
              "
            >
              <img
                alt="team"
                class="
                  flex-shrink-0
                  rounded-lg
                  w-48
                  h-48
                  object-cover object-center
                  sm:mb-0
                  mb-4
                "
                src="https://avatars.githubusercontent.com/u/47443421?s=200&v=4"
              />
              <div class="flex-grow sm:pl-8">
                <h2 class="title-font font-medium text-lg text-gray-900">
                  Zest Framework
                </h2>
                <h3 class="text-gray-500 mb-3">Designer</h3>
                <p class="mb-4">
                  Zest is a simple yet powerful PHP MVC framework for rapid
                  application development that is suited for small to medium
                  scale apps and APIs.
                </p>
              </div>
            </div>
          </a>
        </div>
        <div class="p-4 lg:w-1/2">
          <a href="https://github.com/resourcesr" target="_blank">
            <div
              class="
                h-full
                flex
                sm:flex-row
                flex-col
                items-center
                sm:justify-start
                justify-center
                text-center
                sm:text-left
                transform
                transition
                duration-500
                hover:scale-110
                border-2
              "
            >
              <img
                alt="team"
                class="
                  flex-shrink-0
                  rounded-lg
                  w-48
                  h-48
                  object-cover object-center
                  sm:mb-0
                  mb-4
                "
                src="https://avatars.githubusercontent.com/u/71836448?s=200&v=4"
              />
              <div class="flex-grow sm:pl-8">
                <h2 class="title-font font-medium text-lg text-gray-900">
                  ResourcesR
                </h2>
                <p class="mb-4">An open source study solution.</p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>
